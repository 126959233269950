export enum RoutePaths {
  HOME = '/',
  SIGN_IN = '/sign-in',
  STYLE_PLAN = '/style-plan',
  GUIDES = '/guides',
  MY_ACCOUNT = '/me',
  PROFILE = '/account',
  STYLIST = '/stylist',
  STYLE_PLAN_DAY = '/style-plan/:day',
  UNSUBSCRIBE = '/unsubscribe',
  NOT_FOUND = '*',
  SIGN_OUT = '/sign-out',
  SUPPORT = '/support',
  RENEW_SUBSCRIPTION = '/renew-subscription',
  BOOKING = '/booking',
  SUBSCRIPTION_INFO = '/subscription',
  ONBOARDING = '/onboarding',
  WARDROBE_REVIEW = '/wardrobe-review',
  SESSION_CONFIRMATION = '/session-confirmation',
  SEASONAL_CAPSULE = '/seasonal-capsule',
  COLOR_PALETTE = '/color-palette',
}
