import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, IconButton, Link, Modal, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { useLocalStorageStore, useStore } from 'store';
import { invertedButtonColor } from 'styles';
import { shallow } from 'zustand/shallow';

import { LUMI_PLACE_DOMAIN, SUPPORT_EMAIL, TITLE_ONLY_SUPPORT_EMAIL } from '../../../utils';
import { modalStyles } from './styles';
import { errorButtonMap, errorDescriptionMap, errorIconMap, errorTitleMap } from './utils/data';

export const ErrorModal = memo(() => {
  const { errorType, changeErrorType } = useStore(
    (state) => ({
      errorType: state.errorType,
      changeErrorType: state.changeErrorType,
    }),
    shallow,
  );

  const { domain } = useLocalStorageStore(
    (state) => ({
      domain: state.domain,
    }),
    shallow,
  );

  const closeErrorModal = (): void => {
    changeErrorType(null);
  };

  return (
    <Modal open={!!errorType}>
      <Box sx={modalStyles}>
        <Box position="absolute" top={{ xs: 16, lg: 24 }} right={{ xs: 16, lg: 24 }}>
          <IconButton aria-label="close" size="small" onClick={closeErrorModal} sx={{ color: 'custom.textGrey' }}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Box>
        <Stack gap={8} alignItems="center" maxWidth={435} mx="auto" textAlign="center">
          <Box>{errorIconMap[errorType]}</Box>
          <Box>
            <Typography variant="h2" component="p" fontSize={24} mb={2}>
              {errorTitleMap[errorType]}
            </Typography>
            <Typography variant="body1" fontSize={16} color="custom.textGrey">
              {errorDescriptionMap[errorType]}
            </Typography>
          </Box>
          <Box width={1}>
            <Button fullWidth sx={[invertedButtonColor, { py: 4, mb: 6 }]} onClick={closeErrorModal}>
              {errorButtonMap[errorType]}
            </Button>
            <Link href={`mailto:${domain === LUMI_PLACE_DOMAIN ? TITLE_ONLY_SUPPORT_EMAIL : SUPPORT_EMAIL}`}>
              Contact support
            </Link>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
});
