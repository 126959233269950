import { StopUsingTitleReason, UnsubscribeStep } from 'common-types';
import { StateCreator } from 'zustand';

import { IUnsubscribeStore } from './types';

export const unsubscribeSlice: StateCreator<IUnsubscribeStore, [], [], IUnsubscribeStore> = (set, get) => ({
  stopShopReason: null,
  stopUsingTitleReason: null,
  activeStep: UnsubscribeStep.NOT_TO_SHOP_REASON,
  prevLocation: null,
  showThankYouModal: false,
  decision: null,
  isUserAlreadyHaveDiscount: false,
  setIsUserAlreadyHaveDiscount: (isUserAlreadyHaveDiscount: boolean) => set({ isUserAlreadyHaveDiscount }),
  changeDecision: (decision) => set({ decision }),
  handleNextStep: (): void => {
    const activeStep = get().activeStep;
    const stopUsingLumiReason = get().stopUsingTitleReason;
    const showThankYouModal = get().showThankYouModal;
    const isUserAlreadyHaveDiscount = get().isUserAlreadyHaveDiscount;

    if (showThankYouModal) {
      set({ showThankYouModal: false, activeStep: UnsubscribeStep.OFFER_DISCOUNT });
      return;
    }

    if (activeStep === UnsubscribeStep.NOT_TO_SHOP_REASON) {
      set({ activeStep: UnsubscribeStep.NOT_TO_USE_TITLE_REASON });
      return;
    }

    if (activeStep === UnsubscribeStep.NOT_TO_USE_TITLE_REASON && isUserAlreadyHaveDiscount) {
      set({ activeStep: UnsubscribeStep.CONFIRMATION });
      return;
    }

    if (activeStep === UnsubscribeStep.NOT_TO_USE_TITLE_REASON && stopUsingLumiReason === StopUsingTitleReason.OTHER) {
      set({ activeStep: UnsubscribeStep.CONFIRMATION });
      return;
    }

    if (activeStep === UnsubscribeStep.NOT_TO_USE_TITLE_REASON) {
      set({ activeStep: UnsubscribeStep.OFFER_DISCOUNT });
      return;
    }

    if (activeStep === UnsubscribeStep.CONFIRMATION || activeStep === UnsubscribeStep.INTERVIEW) {
      get().clearUnsubscribeData();
      return;
    }
  },
  setStopShopReason: (stopShopReason) => set({ stopShopReason }),
  setStopUsingTitleReason: (stopUsingLumiReason) => set({ stopUsingTitleReason: stopUsingLumiReason }),
  setActiveStep: (activeStep) => set({ activeStep }),
  handleToConfirmationStep: () => set({ activeStep: UnsubscribeStep.CONFIRMATION }),
  handleToInterviewStep: () => set({ activeStep: UnsubscribeStep.INTERVIEW }),
  clearUnsubscribeData: () =>
    set({
      stopShopReason: null,
      stopUsingTitleReason: null,
      activeStep: UnsubscribeStep.NOT_TO_SHOP_REASON,
      showThankYouModal: false,
      decision: null,
    }),
  setPrevLocation: (prevLocation) => set({ prevLocation }),
});
