import { StateCreator } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { ISessionStorageStore } from './types';

export const sessionStorageSlice: StateCreator<
  ISessionStorageStore,
  [],
  [['zustand/persist', ISessionStorageStore], ...[]]
> = persist(
  (set) => ({
    isPrevSessionCanceled: false,
    updateIsPrevSessionCanceled: (isPrevSessionCanceled) => set({ isPrevSessionCanceled }),
  }),
  {
    name: 'title-app-session-storage',
    storage: createJSONStorage(() => sessionStorage),
  },
);
