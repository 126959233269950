import { FirebaseOptions } from '@firebase/app';

export const firebaseTitleInitialProdConfig: FirebaseOptions = {
  apiKey: 'AIzaSyCAlruWq4RyxfXs1V4qLK4CdenDXK80TBI',
  authDomain: 'fashion-548d1.firebaseapp.com',
  databaseURL: 'https://fashion-548d1-default-rtdb.firebaseio.com',
  projectId: 'fashion-548d1',
  storageBucket: 'fashion-548d1.appspot.com',
  messagingSenderId: '714943891796',
  appId: '1:714943891796:web:0007800bcda9c45dc820c1',
  measurementId: 'G-QPPHG1SR6X',
};

export const firebaseTitleInitialDevConfig: FirebaseOptions = {
  apiKey: 'AIzaSyCyRTlVzOnsqtve9OMf9CNqndSrgkp7ulk',
  authDomain: 'lumi-dev-ca3aa.firebaseapp.com',
  projectId: 'lumi-dev-ca3aa',
  storageBucket: 'lumi-dev-ca3aa.appspot.com',
  messagingSenderId: '831640054005',
  appId: '1:831640054005:web:798c19e4b714fe3f5f7dbc',
  measurementId: 'G-FDY51NZFJN',
};
