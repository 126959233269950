export enum UnsubscribeStep {
  NOT_TO_SHOP_REASON = 'not_to_shop_reason',
  NOT_TO_USE_TITLE_REASON = 'not_to_use_title_reason',
  OFFER_DISCOUNT = 'offer_discount',
  OFFER_TRIAL = 'offer_trial',
  QUESTION = 'question',
  CONFIRMATION = 'confirmation',
  INTERVIEW = 'interview',
}

export enum StopShopReason {
  CONFUSING = 'app_is_confusing',
  UNABLE_TO_CONNECT = 'unable_to_connect',
  UNSATISFACTORY_STYLIST = 'stylist_was_unsatisfactory.',
  BAD_SUGGESTION = 'bad_suggestion',
  EXPECT_CLOTHING_BOX = 'expected_clothing_box',
  PURCHASE_FROM_APP = 'purchased_from_app',
}

export enum StopUsingTitleReason {
  EXPENSIVE = 'expensive',
  EXPECTATION_BROKEN = 'expectation_broken',
  RENEW_LATER = 'renew_later',
  DONT_HAVE_TIME = 'dont_have_time',
  TECHNICAL_PROBLEM = 'technical_problem',
  DONT_HAVE_ADVICE = 'dont_have_advice',
  OTHER = 'other',
}

export enum EUnsubscribeSimplifiedStep {
  ARE_YOU_SURE = 'are_you_sure',
  REASON = 'reason',
}

export enum EStopUsingTitleReason {
  EXPERIENCE_UNSATISFACTORY = 'EXPERIENCE_UNSATISFACTORY',
  UNABLE_TO_SCHEDULE = 'UNABLE_TO_SCHEDULE',
  APP_WAS_CONFUSING = 'APP_WAS_CONFUSING',
  EXPECTED_SUBSCRIPTION_BOX = 'EXPECTED_SUBSCRIPTION_BOX',
  SUBSCRIPTION_FEE = 'SUBSCRIPTION_FEE',
  WANT_RENEW_LATER = 'WANT_RENEW_LATER',
  OTHER = 'OTHER',
}

export enum QuestionAnswer {
  YES = 'yes',
  NO = 'no',
}

export enum ABTestGroup {
  GROUP_A = 'group_a',
  GROUP_B = 'group_b',
}
