export interface IProduct {
  name: GuideType;
  status: GuideStatus;
  productId: string;
  price?: number;
}

export enum GuideType {
  HAIR = 'hair',
  COLOR = 'color',
  WARDROBE = 'wardrobe',
  BODY = 'body',
  STYLE = 'style',
  BODY_GUIDEBOOK = 'body_guidebook',
}

export enum GuideStatus {
  BUY = 'buy',
  OPENED = 'opened',
  NOTIFY = 'request_notify',
  NOTIFIED = 'notify',
}
