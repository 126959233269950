import CloseIcon from '@mui/icons-material/Close';
import { Box, Fade, Grid, IconButton, Modal, Typography } from '@mui/material';
import { ReactComponent as Menu } from 'assets/icons/edit-note.svg';
import Logo from 'assets/icons/logo.png';
import { RoutePaths } from 'common-types';
import { Notes } from 'components/notes';
import { Recent } from 'components/recent';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ReactComponent as CrownIcon } from './assets/crown.svg';

export const HeaderLanding = memo(() => {
  const { isModalOpened, paymentRef, setIsModalOpened } = useStore(
    (state) => ({
      isModalOpened: state.isModalOpened,
      paymentRef: state.paymentRef,
      setIsModalOpened: state.setIsModalOpened,
    }),
    shallow,
  );

  const handleNavigateToPayment = (): void => {
    paymentRef.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Box
        component="header"
        position="fixed"
        top={0}
        left={0}
        px={4}
        width="100%"
        py={2.5}
        zIndex={3}
        bgcolor="common.white"
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid item container>
              <Box component={Link} to={RoutePaths.HOME}>
                <Box component="span" display="block" width={90} height={40}>
                  <Box component="img" src={Logo} alt="logo" sx={{ width: 1, height: 1, objectFit: 'contain' }} />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" gap={3.5}>
              <Grid item container xs>
                <CrownIcon />
              </Grid>
              <Grid item>
                <Box
                  role="button"
                  px={5}
                  py={1.5}
                  bgcolor="custom.orange"
                  borderRadius={1.5}
                  onClick={handleNavigateToPayment}
                >
                  <Typography variant="body1" component="span" fontWeight={500} color="common.white">
                    Premium
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item container>
              {isModalOpened ? (
                <IconButton onClick={(): void => setIsModalOpened(false)} sx={{ p: 0, color: 'common.black' }}>
                  <CloseIcon />
                </IconButton>
              ) : (
                <IconButton onClick={(): void => setIsModalOpened(true)} sx={{ p: 0 }}>
                  <Menu />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Fade in={isModalOpened} timeout={500}>
        <Modal open={isModalOpened} keepMounted onClose={(): void => setIsModalOpened(false)} sx={{ zIndex: 2 }}>
          <Grid
            container
            direction="column"
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            py={10}
            bgcolor="common.white"
          >
            <Grid item>
              <Notes />
            </Grid>
            <Grid item mt="auto">
              <Recent />
            </Grid>
          </Grid>
        </Modal>
      </Fade>
    </>
  );
});
