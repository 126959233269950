import { createTheme, SxProps } from '@mui/material/styles';
import SFProDisplayBold from 'assets/fonts/sf-pro-display/sf-pro-display-bold.woff2';
import SFProDisplayMedium from 'assets/fonts/sf-pro-display/sf-pro-display-medium.woff2';
import SFProDisplayRegular from 'assets/fonts/sf-pro-display/sf-pro-display-regular.woff2';
import SFProTextBold from 'assets/fonts/sf-pro-text/sf-pro-text-bold.woff2';
import SFProTextRegular from 'assets/fonts/sf-pro-text/sf-pro-text-regular.woff2';
import SFProTextRegularItalic from 'assets/fonts/sf-pro-text/sf-pro-text-regular-italic.woff2';
import { Fonts } from 'common-types';

// A module augmentation for custom theme values
declare module '@mui/material/styles' {
  interface IPaletteCustom {
    void: string;
    textBlack: string;
    textGrey: string;
    border: string;
    baseBg: string;
    iconGrey: string;
    orange: string;
    primaryLighter: string;
    textGreyDark: string;
    lightGrey: string;
    textGreyDarker: string;
    buttonColor: string;
    borderColor: string;
    inactiveText: string;
    textToChangeColor: string;
    stepperGrey: string;
    primaryBg: string;
    orangeBg: string;
    link: string;
    borderGrey: string;
    textGreyMiddle: string;
    primaryBgLight: string;
    red: string;
    greyLight: string;
    greyMiddle: string;
    greyDark: string;
    skeleton: string;
  }
  interface Palette {
    custom: IPaletteCustom;
  }
  interface PaletteOptions {
    custom: IPaletteCustom;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    text_underline: true;
  }
}

const textUnderline = {
  position: 'relative',
  outline: 'none',
  background: 'none',
  color: '#16171B',
  textTransform: 'none',
  fontSize: 17,
  fontWeight: 400,
  fontFamily: `${Fonts.TITLE}, sans-serif`,
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 5,
    left: 0,
    width: '100%',
    height: '1px',
    backgroundColor: 'currentColor',
    transition: 'transform 0.2s ease, opacity 0.2s ease',
    transform: 'scaleX(1)',
    transformOrigin: 'left',
  },
  '&:hover, &:focus-visible': {
    background: 'none',
    color: '#16171B',
    '&::after': {
      transformOrigin: 'right',
      transform: 'scaleX(0)',
    },
  },
  '&:focus-visible': {
    opacity: 0.8,
  },
} satisfies SxProps;

const theme = createTheme({
  palette: {
    primary: {
      main: '#DE3A6B',
      light: '#FEF2F4',
      dark: '#E56289',
    },
    secondary: {
      main: '#AEAEB2',
      dark: '#D0D0D0',
      light: '#F9F9F9',
    },
    success: {
      main: '#6DB98B',
    },
    error: {
      main: '#FF3B30',
    },
    custom: {
      void: '#292929',
      textBlack: '#272727',
      textGrey: '#8E8E93',
      border: '#EDEDF0',
      baseBg: '#F9F8FD',
      iconGrey: '#ACACAC',
      orange: '#FF9900',
      primaryLighter: '#FEF2F4',
      textGreyDark: '#939FB0',
      textGreyDarker: '#6E6A80',
      lightGrey: '#E5E5EA',
      buttonColor: '#F2F2F7',
      borderColor: '#E8E8E8',
      inactiveText: '#A0A0A0',
      textToChangeColor: '#686868',
      stepperGrey: '#BEBEBE',
      primaryBg: '#F3F1FB',
      orangeBg: '#F0A637',
      link: '#007AFF',
      borderGrey: '#D1D1D6',
      textGreyMiddle: '#666666',
      primaryBgLight: '#EEEBF4',
      red: '#DA1919',
      greyLight: '#A6A6A6',
      greyMiddle: '#979797',
      greyDark: '#91979D',
      skeleton: '#EBE9F5',
    },
  },
  spacing: (x: number) => x * 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 768,
      lg: 1024,
      xl: 1356,
    },
  },
  typography: {
    fontFamily: [Fonts.TEXT, '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightBold: 700,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    h1: {
      fontFamily: [Fonts.TITLE, '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
      fontSize: 30,
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: [Fonts.TITLE, '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: [Fonts.TITLE, '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.2,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    caption: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1.2,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': {
          fontFamily: `${Fonts.TEXT}, sans-serif`,
          fontStyle: 'normal',
          fontDisplay: 'swap',
          fontWeight: 400,
          src: `url(${SFProTextRegular}) format('woff2')`,
        },
        fallbacks: [
          {
            fontFamily: `${Fonts.TEXT}, sans-serif`,
            fontStyle: 'italic',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${SFProTextRegularItalic}) format('woff2')`,
          },
          {
            fontFamily: `${Fonts.TEXT}, sans-serif`,
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 700,
            src: `url(${SFProTextBold}) format('woff2')`,
          },
          {
            fontFamily: Fonts.TITLE,
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${SFProDisplayRegular}) format('woff2')`,
          },
          {
            fontFamily: Fonts.TITLE,
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 500,
            src: `url(${SFProDisplayMedium}) format('woff2')`,
          },
          {
            fontFamily: Fonts.TITLE,
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 700,
            src: `url(${SFProDisplayBold}) format('woff2')`,
          },
        ],
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },
        html: {
          height: '100%',
        },
        body: {
          webkitFontSmoothing: 'antialiased',
          boxSizing: 'border-box',
          color: '#272727',
          backgroundColor: '#fff',
          margin: 0,
          fontWeight: 400,
          lineHeight: 1.43,
          scrollBehavior: 'smooth',
          height: '100%',
          overflowX: 'hidden',
        },
        '#root': {
          height: '100%',
          minHeight: '-webkit-fill-available',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontFamily: 'inherit',
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& fieldset': {
              borderColor: '#E8E8E8',
            },
          },
          '& ::placeholder': {
            fontFamily: 'Inter, sans-serif',
          },
          '& .MuiOutlinedInput-input': {
            fontSize: 16,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      variants: [
        {
          props: { variant: 'text_underline' },
          style: {
            padding: 0,
            ...textUnderline,
          },
        },
      ],
      styleOverrides: {
        root: {
          fontFamily: `${Fonts.TEXT}, sans-serif`,
          lineHeight: 1.2,
          fontSize: 15,
          fontWeight: 700,
          boxShadow: 'none',
          borderRadius: 50,
          paddingTop: 5,
          paddingBottom: 5,
          paddingRight: 24,
          paddingLeft: 24,
          minHeight: 28,
          '&:hover, &:active': {
            boxShadow: 'none',
            backgroundColor: '#DE3A6B',
            color: '#fff',
            '&.Mui-disabled': {
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.26)',
            },
          },
          '&.Mui-focusVisible': {
            boxShadow: 'none',
            backgroundColor: '#DE3A6B',
            color: '#fff',
          },
        },
        containedPrimary: {
          backgroundColor: '#F2F2F7',
          color: '#DE3A6B',
        },
        outlinedPrimary: {
          borderColor: '#000',
          color: '#000',
          '&:hover, &:active': {
            color: '#fff',
          },
          '&.Mui-focusVisible': {
            color: '#fff',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          '& svg': {
            color: '#D9D9D9',
          },
          '&.Mui-checked': {
            '& svg': {
              color: '#DE3A6B',
            },
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          outline: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'inherit',
          fontSize: 12,
          lineHeight: 1.2,
          padding: '14px 12px',
          backgroundColor: '#fff',
          color: '#272727',
          border: '1px solid #EDEDE8',
          borderRadius: '6px',
        },
        arrow: {
          color: '#fff',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&::before': {
            display: 'none',
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        vertical: {},
        root: {
          '&.MuiStep-vertical': {
            '&.Mui-disabled': {
              color: 'black',
              opacity: 0,
            },
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        vertical: {
          transition: 'opacity 0.3s ease-in-out',
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
        root: {
          '&.MuiStepLabel-horizontal': {
            '& .MuiStepLabel-labelContainer': {
              color: '#BEBEBE',
            },
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          '&.MuiStepConnector-vertical': {
            marginLeft: 16,

            '& .MuiStepConnector-lineVertical': {
              borderWidth: 3,
              height: 67,
            },
          },
          '&.MuiStepConnector-horizontal': {
            '& .MuiStepConnector-line': {
              borderColor: '#BEBEBE',
            },
          },
          '&.Mui-completed': {
            '& .MuiStepConnector-line': {
              borderColor: '#DE3A6B',
            },
          },
          '&.Mui-active': {
            '& .MuiStepConnector-line': {
              borderColor: '#DE3A6B',
            },
          },
        },
        line: {
          borderTopWidth: 3,
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        variant: 'permanent',
      },
      styleOverrides: {
        root: {
          '& .MuiDrawer-paper': {
            width: 210,
            boxSizing: 'border-box',
          },
        },
      },
    },
  },
});

export default theme;
