import { Identify, identify } from '@amplitude/analytics-browser';
import { Box, CircularProgress } from '@mui/material';
import { RoutePaths } from 'common-types';
import { PrivateRoute } from 'components/private-route';
import { LayoutEmpty } from 'layout/empty';
import { LayoutLanding } from 'layout/landing';
import { LayoutMain } from 'layout/main';
import { FC, Suspense, useEffect, useLayoutEffect, useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useGetUserData } from '../hooks/use-get-user-data';
import { useAnalytics } from '../services/analytics';
import { isProduction } from '../utils';
import { routesLayoutMap } from './data';
import { additionalRoutes, landingRoutes, mainRoutes } from './routes';

const UiFullScreenLoader: FC = () => (
  <Box height="100vh" top="50%" left="50%" position="fixed" width="100%" justifyContent="center" alignItems="center">
    <CircularProgress size={60} />
  </Box>
);

const Router: FC = () => {
  const location = useLocation();
  const { trackEvent } = useAnalytics();
  const pathname = location.pathname;
  const { userDomain, userId } = useGetUserData();

  useLayoutEffect(() => {
    if (
      !pathname.includes(RoutePaths.STYLE_PLAN + '/') &&
      !pathname.includes(RoutePaths.STYLE_PLAN) &&
      !pathname.includes(RoutePaths.SUBSCRIPTION_INFO) &&
      !pathname.includes(RoutePaths.UNSUBSCRIBE) &&
      !pathname.includes(RoutePaths.WARDROBE_REVIEW + '/') &&
      !pathname.includes(RoutePaths.WARDROBE_REVIEW) &&
      !pathname.includes(RoutePaths.SIGN_IN)
    ) {
      trackEvent(`${pathname.slice(1).replace('-', '_') || 'home'}_screen_view`);
    }
    window.scroll(0, 0);
  }, [pathname]);

  const identifyEvent = useMemo(() => new Identify(), []);

  useEffect(() => {
    identifyEvent.set('user_environment', isProduction() ? 'prod_web_v0' : 'dev_web_v0');
    if (userDomain) {
      identifyEvent.set('user_domain', userDomain);
    }

    if (userId) {
      identifyEvent.set('user_id', userId);
    }

    identify(identifyEvent);
  }, [identifyEvent, userDomain, userId]);

  return (
    <Suspense fallback={<UiFullScreenLoader />}>
      <Routes>
        {/* TODO make this for all routes */}
        <Route path={RoutePaths.HOME} element={routesLayoutMap[pathname as RoutePaths] || <LayoutMain />}>
          {mainRoutes.map(({ path, isPrivate, Component, isOnlyForSubscribed }) =>
            isPrivate ? (
              <Route key={path} element={<PrivateRoute isOnlyForSubscribed={isOnlyForSubscribed} />}>
                <Route path={path} element={<Component />} />
              </Route>
            ) : (
              <Route key={path} path={path} element={<Component />} />
            ),
          )}
        </Route>
        <Route path={RoutePaths.HOME} element={<LayoutEmpty />}>
          {additionalRoutes.map(({ path, isPrivate, Component }) =>
            isPrivate ? (
              <Route key={path} element={<PrivateRoute />}>
                <Route path={path} element={<Component />} />
              </Route>
            ) : (
              <Route key={path} path={path} element={<Component />} />
            ),
          )}
        </Route>
        <Route path={RoutePaths.HOME} element={<LayoutLanding />}>
          {landingRoutes.map(({ path, isPrivate, Component }) =>
            isPrivate ? (
              <Route key={path} element={<PrivateRoute />}>
                <Route path={path} element={<Component />} />
              </Route>
            ) : (
              <Route key={path} path={path} element={<Component />} />
            ),
          )}
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Router;
