import { FirebaseOptions } from '@firebase/app';
import {
  FirebaseSessionContent,
  IFirebaseSessionContent,
  IMood,
  IProgressData,
  TDynamicUpsellContent,
} from 'common-types';
import { IOnboardingContent } from 'common-types/onboarding';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { collection, getDocs, getFirestore } from 'firebase/firestore/lite';
import { fetchAndActivate, getBoolean, getRemoteConfig, getString } from 'firebase/remote-config';
import {
  INTERVIEW_OFFER,
  isProduction,
  ONBOARDING_COLLECTION,
  ONBOARDING_SCREEN,
  PAID_OPTIONS_STATUS,
  SESSION_CONTENT,
  SHOW_EXTENDED_ONBOARDING,
  UNSUBSCRIBE_AB_TEST,
} from 'utils';

import { firebaseTitleInitialDevConfig, firebaseTitleInitialProdConfig } from './config';
import {
  IFirebaseContent,
  IFirebaseInterview,
  IFirebaseOnboarding,
  IFirebasePaidOptions,
  IFirebaseRemoteConfig,
} from './utils/types';

const initializeFirebase = (devConfig: FirebaseOptions, prodConfig: FirebaseOptions): FirebaseOptions =>
  isProduction() ? prodConfig : devConfig;

const firebaseTitleInitialApp =
  getApps().length === 0
    ? initializeApp(initializeFirebase(firebaseTitleInitialDevConfig, firebaseTitleInitialProdConfig))
    : getApp();
const firebaseTitleInitialDB = getFirestore(firebaseTitleInitialApp);
const remoteConfigTitleInitial = getRemoteConfig(firebaseTitleInitialApp);

export const getDynamicUpsellContent = async (id: string, domain: string): Promise<TDynamicUpsellContent | void> => {
  const upsellMappingData = collection(firebaseTitleInitialDB, 'upsellMapping');
  const upsellPagesData = collection(firebaseTitleInitialDB, 'upsellPages');

  try {
    const contentUpsellsSnapshot = await getDocs(upsellMappingData);
    const contentPagesSnapshot = await getDocs(upsellPagesData);
    const modal = contentUpsellsSnapshot.docs.find((doc) => doc.id === id);
    const defaultModal = contentUpsellsSnapshot.docs.find((doc) => doc.id === 'default');
    const modalId = modal?.data() || defaultModal?.data();
    const content = contentPagesSnapshot.docs.find((doc) => doc.id === modalId?.pages?.[domain]);
    const defaultContent = contentPagesSnapshot.docs.find((doc) => doc.id === modalId?.pages?.['default']);

    return (content?.data() as TDynamicUpsellContent) || (defaultContent?.data() as TDynamicUpsellContent);
  } catch (e) {
    console.error('Error getting dynamic upsells content');
  }
};

export const getFirebaseContent = async (): Promise<IFirebaseContent> => {
  const content = collection(firebaseTitleInitialDB, 'content');
  const mood = collection(firebaseTitleInitialDB, 'mood');

  try {
    const contentSnapshot = await getDocs(content);
    const moodSnapshot = await getDocs(mood);

    const contentList = contentSnapshot.docs.map((doc) => doc.data()) as IProgressData[];
    const moodList = moodSnapshot.docs.map((doc) => doc.data()) as Record<string, IMood>[];

    return {
      sortedContentList: contentList.sort((a, b) => a.id - b.id),
      contentList,
      moodList,
    };
  } catch (e) {
    throw new Error('Error getting content');
  }
};

export const getFirebaseOnboarding = async (): Promise<IFirebaseOnboarding> => {
  const onboarding = collection(firebaseTitleInitialDB, ONBOARDING_COLLECTION);
  let activeOnboarding: string;

  try {
    const onboardingSnapshot = await getDocs(onboarding);
    await fetchAndActivate(remoteConfigTitleInitial);
    activeOnboarding = getString(remoteConfigTitleInitial, ONBOARDING_SCREEN);

    let onboardingList: IOnboardingContent;

    onboardingSnapshot.docs.find((doc) => {
      if (doc.id === activeOnboarding) {
        onboardingList = doc.data() as IOnboardingContent;
      }
    });

    return {
      onboardingList,
      activeOnboarding,
    };
  } catch (e) {
    throw new Error('Error getting content');
  }
};

export const getFirebaseSessionsContent = async (): Promise<IFirebaseSessionContent[]> => {
  const sessionsContent = collection(firebaseTitleInitialDB, SESSION_CONTENT);

  try {
    const sessionsContentSnapshot = await getDocs(sessionsContent);
    return sessionsContentSnapshot.docs.reduce((acc, doc) => {
      return [
        ...acc,
        {
          id: doc.id,
          content: doc.data() as FirebaseSessionContent,
        },
      ];
    }, [] as IFirebaseSessionContent[]);
  } catch (e) {
    throw new Error('Error getting content');
  }
};

export const getFirebaseInterviewOffer = async (): Promise<IFirebaseInterview> => {
  await fetchAndActivate(remoteConfigTitleInitial);
  const interviewOffer = getBoolean(remoteConfigTitleInitial, INTERVIEW_OFFER);

  return { interviewOffer };
};

export const getFirebasePaidOptions = async (): Promise<IFirebasePaidOptions> => {
  await fetchAndActivate(remoteConfigTitleInitial);
  const isPaidOptionsActive = getBoolean(remoteConfigTitleInitial, PAID_OPTIONS_STATUS);

  return { isPaidOptionsActive };
};

const safeParse = (value: string): string[] => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return [];
  }
};

// TODO: This is primary service for remote config fetching
// all other must be destroyed
export const getFirebaseRemoteConfig = async (): Promise<IFirebaseRemoteConfig> => {
  await fetchAndActivate(remoteConfigTitleInitial);

  return {
    isInterviewOfferActive: getBoolean(remoteConfigTitleInitial, INTERVIEW_OFFER),
    isPaidOptionsActive: getBoolean(remoteConfigTitleInitial, PAID_OPTIONS_STATUS),
    isUnsubscribeABTestActive: getBoolean(remoteConfigTitleInitial, UNSUBSCRIBE_AB_TEST),
    activeOnboarding: getString(remoteConfigTitleInitial, ONBOARDING_SCREEN),
    showExtendedOnboarding: getBoolean(remoteConfigTitleInitial, SHOW_EXTENDED_ONBOARDING),
    interviewOffer: getBoolean(remoteConfigTitleInitial, INTERVIEW_OFFER),
    interviewAnswers:
      getString(remoteConfigTitleInitial, 'interviewAnswers') &&
      safeParse(getString(remoteConfigTitleInitial, 'interviewAnswers')),
    interviewLink: getString(remoteConfigTitleInitial, 'interviewLink'),
  };
};
