import { setUserId as amplitudeSetUserId } from '@amplitude/analytics-browser';
import { useQuery } from '@tanstack/react-query';
import { QueryKey, UserStatus } from 'common-types';
import { useInitSession } from 'hooks/use-init-session';
import { useEffect, useMemo } from 'react';
import { useAnalytics } from 'services/analytics';
import api from 'services/api';
import { useLocalStorageStore, useStore } from 'store';
import { CHEAP_GUIDE, TRIAL_GUIDE } from 'utils';
import { EU_TAX_COUNTRIES } from 'utils/countries';
import { shallow } from 'zustand/shallow';

import { IUseGetUserData } from './utils/types';

export const useGetUserData = (): IUseGetUserData => {
  const { trackEvent } = useAnalytics();
  const { isUserLoggedIn } = useInitSession();

  const { isSessionInitialized, setIsUserWithVat } = useStore(
    (state) => ({
      isSessionInitialized: state.isSessionInitialized,
      setIsUserWithVat: state.setIsUserWithVat,
    }),
    shallow,
  );

  const { setUserId, setDomain } = useLocalStorageStore(
    (state) => ({
      setUserId: state.setUserId,
      setDomain: state.setDomain,
    }),
    shallow,
  );

  const {
    data: userData,
    isError,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery([QueryKey.USER_DATA], () => api.getUserInfo(), {
    enabled: !!isUserLoggedIn || isSessionInitialized,
    onSuccess: (data) => {
      if (data?.id) {
        setUserId(data.id);
        amplitudeSetUserId(data.id);
      }

      if (data?.domain) {
        setDomain(data.domain);
      }

      if (data?.country && EU_TAX_COUNTRIES.includes(data?.country)) {
        setIsUserWithVat(true);
      }
    },
  });

  const pureGuides = useMemo(() => {
    // it's a temporary fix to remove trial and cheap guides from the list
    return Array.from(new Set(userData?.guides?.filter((guide) => guide !== TRIAL_GUIDE && guide !== CHEAP_GUIDE)));
  }, [userData?.guides]);

  const isSubscriptionExpired = userData?.status === UserStatus.CANCELED || userData?.status === UserStatus.DEFAULT;

  useEffect(() => {
    if (isSuccess) {
      // @ts-ignore
      window.zE('messenger', 'loginUser', function (callback: any) {
        try {
          callback(userData?.zendeskJwtToken);
        } catch (e) {
          trackEvent('chat_userId_transfer_error', {
            error: JSON.stringify(e),
          });
        }
      });
    }
  }, [isSuccess, trackEvent, userData?.zendeskJwtToken]);

  return {
    userData,
    isError,
    isLoading,
    isSuccess,
    userId: userData?.id,
    userNotes: userData?.notes || [],
    userProgress: userData?.userProgress,
    userSelectedMood: userData?.selectedMood,
    userRecent: userData?.recent || [],
    userName: userData?.userName,
    userEmail: userData?.email,
    userSubscriptionId: userData?.subscriptionId,
    userSchema: userData?.schema,
    userStatus: userData?.status,
    userGoal: userData?.userGoal || '',
    userUltimateWhy: userData?.userUltimateWhy || '',
    userPhoto: userData?.photo,
    userGuides: userData?.guides,
    userPureGuides: pureGuides,
    userDomain: userData?.domain,
    userCountry: userData?.country,
    isLifetimePremium: userData?.isLifetimePremium,
    isUpsellFinished: userData?.isUpsellFinished,
    isSubscriptionExpired,
    refetch,
  };
};
